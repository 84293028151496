import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';

export default function VisualizarBriefing({ match }) {
    //ok
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const history = useHistory();
    const [dados, setDados] = useState({
        evento: { empresa: empresa }
    });

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/eventoBriefingDados/' + id,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = response.data.data;
                var modelo = dadosRecebidos.modelo ?? 'AdvisoryBoard';
                if (modelo === 'AdvisoryBoard') {
                    history.push("/cadastroBriefingAdvisoryBoard/" + id, { visualizar: true });
                }
                if (modelo === 'EventoTerceiro') {
                    history.push("/cadastroBriefingEventoTerceiro/" + id, { visualizar: true });
                }
                if (modelo === 'OrganizadoSede') {
                    history.push("/cadastroBriefingOrganizadoSede/" + id, { visualizar: true });
                }
                if (modelo === 'OrganizadoForcaVendas') {
                    history.push("/cadastroBriefingOrganizadoForcaVendas/" + id, { visualizar: true });
                }
            }).catch(err => {
                //console.log(err)
            });
        }//if id
        else {
            setDados(dados);
        }
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Redirecionamento...</h3>
                </div>
            </div>
        </ErpTemplate>
    );
}