import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaBackward, FaDownload, FaPaperPlane, FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { bin2hex, data, data2Js, dataMysql, extract, pontoVirgula } from '../../util/util';
import Data from '../../components/Data';
import { toast } from 'react-toastify';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import InputMask from 'react-input-mask';
import FormButton from '../../components/FormButton';
import NavItem from '../../components/NavItem';

export default function CadastroBriefingModelo4({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    const [enviado, setEnviado] = useState(false);
    const [dados, setDados] = useState({
        modelo: 'modelo4',
        participantesPatrocinados: 'nao',
        nomeParticipantesPatrocinados: 'Não',
        evento: { empresa: empresa }
    });
    const history = useHistory();
    let { state } = useLocation();
    const visualizar = state?.visualizar ?? false;

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: visualizar ? '/eventoBriefingDados/' + id : '/eventoBriefing/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'produtoprincipal',
                        'unidadenegocio',
                    ]
                });

                //transforma data para javascript
                dadosRecebidos.evento.data = data(dadosRecebidos.evento.data);
                dadosRecebidos.evento.dataFim = data(dadosRecebidos.evento.dataFim);

                //transforma dados no formato aceito pelo reactselect
                if (dadosRecebidos.especialidade?.constructor !== Array)
                    dadosRecebidos.especialidade = [];
                dadosRecebidos.especialidade = dadosRecebidos.especialidade.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.tipoParticipanteAlvo?.constructor !== Array)
                    dadosRecebidos.tipoParticipanteAlvo = [];
                dadosRecebidos.tipoParticipanteAlvo = dadosRecebidos.tipoParticipanteAlvo.map(function (objeto) {
                    return {
                        value: objeto,
                        label: objeto
                    };
                });

                if (dadosRecebidos.outrosprodutos?.constructor !== Array)
                    dadosRecebidos.outrosprodutos = [];
                dadosRecebidos.outrosprodutos = dadosRecebidos.outrosprodutos.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.evento?.responsavel?.constructor !== Array)
                    dadosRecebidos.evento.responsavel = [];
                dadosRecebidos.evento.responsavel = dadosRecebidos.evento.responsavel.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.evento?.responsavelcliente?.constructor !== Array)
                    dadosRecebidos.evento.responsavelcliente = [];
                dadosRecebidos.evento.responsavelcliente = dadosRecebidos.evento.responsavelcliente.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                setDados({ ...dados, ...dadosRecebidos });
            }).catch(err => {
                //console.log(err)
            });
        }//if id
    };

    const validar = () => {
        //nome
        var nome = document.querySelector("#nome");
        if (nome.value === '') {
            toast.error('É necessário preencher o Nome do evento');
            setTimeout(() => {
                nome.focus();
            }, 500);
            return false;
        }

        return true;
    };

    const validarTudo = () => {
        //ordemInterna
        var ordemInterna = document.querySelector("#ordemInterna");
        if (ordemInterna.value === '') {
            toast.error('É necessário preencher a Ordem Interna');
            setTimeout(() => {
                ordemInterna.focus();
            }, 500);
            return false;
        }

        //totalPrevisto
        var totalPrevisto = document.querySelector("#totalPrevisto");
        if (totalPrevisto.value === '') {
            toast.error('É necessário preencher o Total Previsto');
            setTimeout(() => {
                totalPrevisto.focus();
            }, 500);
            return false;
        }

        //nome
        var nome = document.querySelector("#nome");
        if (nome.value === '') {
            toast.error('É necessário preencher o Nome do evento');
            setTimeout(() => {
                nome.focus();
            }, 500);
            return false;
        }

        //tipo
        var tipo = document.querySelector("#tipo");
        if ((dados.evento?.tipo ?? '') === '') {
            toast.error('É necessário preencher a Tipo do Registro');
            setTimeout(() => {
                tipo.focus();
            }, 500);
            return false;
        }

        // nacionalidade
        var nacionalidade = document.querySelector("#nacionalidade");
        if ((dados.evento?.nacionalidade ?? '') === '') {
            toast.error('É necessário preencher a Nacionalidade');
            setTimeout(() => {
                nacionalidade.focus();
            }, 500);
            return false;
        }

        var data = document.querySelector("#data");
        var dataFim = document.querySelector("#dataFim");
        var hoje = new Date();
        hoje.setHours(0, 0, 0, 0);

        if (!dados.evento.data) {
            toast.error('É necessário preencher a Data de Início');
            setTimeout(() => {
                data.focus();
            }, 500);
            return false;
        }

        if (dados.evento.data) {
            var data2Js1 = data2Js(dados.evento.data);
            if (data2Js1 < hoje) {
                toast.error('A Data deve ser maior que hoje');
                setTimeout(() => {
                    data.focus();
                }, 500);
                return false;
            }
            // hora
            var hora = document.querySelector("#hora");
            if ((dados.evento?.hora ?? '') === '') {
                toast.error('É necessário preencher o Horário Inicial');
                setTimeout(() => {
                    hora.focus();
                }, 500);
                return false;
            }
        }

        if (!dados.evento.dataFim) {
            toast.error('É necessário preencher a Data Final');
            setTimeout(() => {
                dataFim.focus();
            }, 500);
            return false;
        }

        if (dados.evento.dataFim) {
            var dataFimJs1 = data2Js(dados.evento.dataFim);
            if (dataFimJs1 < hoje) {
                toast.error('A Data Final deve ser maior que hoje');
                setTimeout(() => {
                    dataFim.focus();
                }, 500);
                return false;
            }
            // horaFim
            var horaFim = document.querySelector("#horaFim");
            if ((dados.evento?.horaFim ?? '') === '') {
                toast.error('É necessário preencher o Horário Final');
                setTimeout(() => {
                    horaFim.focus();
                }, 500);
                return false;
            }
        }

        if (dados.evento.data && dados.evento.dataFim) {
            var data2Js2 = data2Js(dados.evento.data);
            var dataFimJs2 = data2Js(dados.evento.dataFim);
            if (dataFimJs2 < data2Js2) {
                toast.error('A Data Final deve ser maior que a Data de Início');
                setTimeout(() => {
                    dataFim.focus();
                }, 500);
                return false;
            }
        }

        // tipoRealizacao
        var tipoRealizacao = document.querySelector("#tipoRealizacao");
        if ((dados.evento?.tipoRealizacao ?? '') === '') {
            toast.error('É necessário preencher o Formato');
            setTimeout(() => {
                tipoRealizacao.focus();
            }, 500);
            return false;
        }

        // unidadenegocio
        var unidadenegocio = document.querySelector("#unidadenegocio");
        if ((dados.unidadenegocio ?? '') === '') {
            toast.error('É necessário preencher a Unidade de Negócio');
            setTimeout(() => {
                unidadenegocio.focus();
            }, 500);
            return false;
        }

        // produtoPrincipal
        var produtoprincipal = document.querySelector("#produtoprincipal");
        if ((dados.produtoprincipal ?? '') === '') {
            toast.error('É necessário preencher o Produto Principal');
            setTimeout(() => {
                produtoprincipal.focus();
            }, 500);
            return false;
        }

        // grupoPacienteEnvolvido
        var grupoPacienteEnvolvido = document.querySelector("#grupoPacienteEnvolvido");
        if ((dados.grupoPacienteEnvolvido ?? '') === '') {
            toast.error('É necessário preencher o Grupo de Paciente Envolvido');
            setTimeout(() => {
                grupoPacienteEnvolvido.focus();
            }, 500);
            return false;
        }

        // requisitanteEvento
        var requisitanteEvento = document.querySelector("#requisitanteEvento");
        if (requisitanteEvento.value === '') {
            toast.error('É necessário preencher o Nome do Requisitante do Evento');
            setTimeout(() => {
                requisitanteEvento.focus();
            }, 500);
            return false;
        }

        // emailRequisitanteEvento
        var emailRequisitanteEvento = document.querySelector("#emailRequisitanteEvento");
        if (emailRequisitanteEvento.value === '') {
            toast.error('É necessário preencher o Email do Requisitante do Evento');
            setTimeout(() => {
                emailRequisitanteEvento.focus();
            }, 500);
            return false;
        }

        // responsavel
        var responsavel = document.querySelector("#responsavel");
        if ((dados.evento?.responsavel ?? []).length === 0) {
            toast.error('É necessário preencher o Responsável');
            setTimeout(() => {
                responsavel.focus();
            }, 500);
            return false;
        }

        // responsavelcliente
        var responsavelcliente = document.querySelector("#responsavelcliente");
        if ((dados.evento?.responsavelcliente ?? []).length === 0) {
            toast.error('É necessário preencher o Responsável no Cliente');
            setTimeout(() => {
                responsavelcliente.focus();
            }, 500);
            return false;
        }

        //membrosEquipe
        var membrosEquipe = document.querySelector("#membrosEquipe");
        if ((dados.membrosEquipe ?? "") === "") {
            toast.error('É necessário preencher os Membros da Equipe');
            setTimeout(() => {
                membrosEquipe.focus();
            }, 500);
            return false;
        }
        //aprovadorUnidadeNegocio
        var aprovadorUnidadeNegocio = document.querySelector("#aprovadorUnidadeNegocio");
        if ((dados.aprovadorUnidadeNegocio ?? "") === "") {
            toast.error('É necessário preencher o Aprovador da Unidade de Negócio');
            setTimeout(() => {
                aprovadorUnidadeNegocio.focus();
            }, 500);
            return false;
        }
        //aprovadorAreaMedica
        var aprovadorAreaMedica = document.querySelector("#aprovadorAreaMedica");
        if ((dados.aprovadorAreaMedica ?? "") === "") {
            toast.error('É necessário preencher o Aprovador da Área Médica');
            setTimeout(() => {
                aprovadorAreaMedica.focus();
            }, 500);
            return false;
        }

        // objetivoEvento
        var objetivoEvento = document.querySelector("#objetivoEvento");
        if (objetivoEvento.value === '') {
            toast.error('É necessário preencher o Objetivo do Evento');
            setTimeout(() => {
                objetivoEvento.focus();
            }, 500);
            return false;
        }

        // necessidadesEvento
        var necessidadesEvento = document.querySelector("#necessidadesEvento");
        if (necessidadesEvento.value === '') {
            toast.error('É necessário preencher as Necessidades do Evento');
            setTimeout(() => {
                necessidadesEvento.focus();
            }, 500);
            return false;
        }

        // aconselhamentoExterno
        var aconselhamentoExterno = document.querySelector("#aconselhamentoExterno");
        if ((dados.aconselhamentoExterno ?? "") === "") {
            toast.error('É necessário preencher a Necessidade de Aconselhamento Externo');
            setTimeout(() => {
                aconselhamentoExterno.focus();
            }, 500);
            return false;
        }
        if ((dados.aconselhamentoExterno ?? "") === "OutrosOp") {
            //aconselhamentoExternoOutros
            var aconselhamentoExternoOutros = document.querySelector("#aconselhamentoExternoOutros");
            if (aconselhamentoExternoOutros.value === '') {
                toast.error('É necessário preencher o Aconselhamento Externo');
                setTimeout(() => {
                    aconselhamentoExternoOutros.focus();
                }, 500);
                return false;
            }
        }

        // tipoEngajamento
        var tipoEngajamento = document.querySelector("#tipoEngajamento");
        if ((dados.tipoEngajamento ?? "") === "") {
            toast.error('É necessário preencher o Service/Deliverable/Advice Usage');
            setTimeout(() => {
                tipoEngajamento.focus();
            }, 500);
            return false;
        }
        if ((dados.tipoEngajamento ?? "") === "OutrosOp") {
            //tipoEngajamentoOutros
            var tipoEngajamentoOutros = document.querySelector("#tipoEngajamentoOutros");
            if (tipoEngajamentoOutros.value === '') {
                toast.error('É necessário preencher o Service/Deliverable/Advice Usage');
                setTimeout(() => {
                    tipoEngajamentoOutros.focus();
                }, 500);
                return false;
            }
        }

        // quantidadePalestrantes
        var quantidadePalestrantes = document.querySelector("#quantidadePalestrantes");
        if (quantidadePalestrantes.value === '') {
            toast.error('É necessário preencher a Quantidade de Palestrantes');
            setTimeout(() => {
                quantidadePalestrantes.focus();
            }, 500);
            return false;
        }
        //areaTerapeuticaExpert
        var areaTerapeuticaExpert = document.querySelector("#areaTerapeuticaExpert");
        if (areaTerapeuticaExpert.value === '') {
            toast.error('É necessário preencher a Area Terapeutica Expert');
            setTimeout(() => {
                areaTerapeuticaExpert.focus();
            }, 500);
            return false;
        }

        // crossBoardPalestranteInternacional
        var crossBoardPalestranteInternacional = document.querySelector("#crossBoardPalestranteInternacional");
        if (crossBoardPalestranteInternacional.value === '') {
            toast.error('É necessário preencher o Cross Board do Palestrante Internacional');
            setTimeout(() => {
                crossBoardPalestranteInternacional.focus();
            }, 500);
            return false;
        }

        //quantidadeHcp
        var quantidadeHcp = document.querySelector("#quantidadeHcp");
        if (quantidadeHcp.value === '') {
            toast.error('É necessário preencher a Quantidade de HCP');
            setTimeout(() => {
                quantidadeHcp.focus();
            }, 500);
            return false;
        }

        //quantidadeNaoHcp
        var quantidadeNaoHcp = document.querySelector("#quantidadeNaoHcp");
        if (quantidadeNaoHcp.value === '') {
            toast.error('É necessário preencher a Quantidade de Nao HCP');
            setTimeout(() => {
                quantidadeNaoHcp.focus();
            }, 500);
            return false;
        }

        // quantidadeStaffs
        var quantidadeStaffs = document.querySelector("#quantidadeStaffs");
        if ((dados.evento?.quantidadeStaffs ?? "") === "") {
            toast.error('É necessário preencher a Quantidade de Staffs');
            setTimeout(() => {
                quantidadeStaffs.focus();
            }, 500);
            return false;
        }

        //racionalEscolhaParticipante
        var racionalEscolhaParticipante = document.querySelector("#racionalEscolhaParticipante");
        if (racionalEscolhaParticipante.value === '') {
            toast.error('É necessário preencher o Racional de Escolha de Participante');
            setTimeout(() => {
                racionalEscolhaParticipante.focus();
            }, 500);
            return false;
        }

        // tipoParticipanteAlvo
        var tipoParticipanteAlvo = document.querySelector("#tipoParticipanteAlvo");
        if ((dados.tipoParticipanteAlvo ?? []).length === 0) {
            toast.error('É necessário preencher o Tipo de Participante Alvo');
            setTimeout(() => {
                tipoParticipanteAlvo.focus();
            }, 500);
            return false;
        }

        //especialidade
        var especialidade = document.querySelector("#especialidade");
        if ((dados.especialidade ?? []).length === 0) {
            toast.error('É necessário preencher a Especialidade');
            setTimeout(() => {
                especialidade.focus();
            }, 500);
            return false;
        }

        // necessidadeAereoTransfer
        var necessidadeAereoTransfer = document.querySelector("#necessidadeAereoTransfer");
        if ((dados.necessidadeAereoTransfer ?? "") === "") {
            toast.error('É necessário preencher a Necessidade de Aéreo e Transfer');
            setTimeout(() => {
                necessidadeAereoTransfer.focus();
            }, 500);
            return false;
        }

        // necessidadeHospedagem
        var necessidadeHospedagem = document.querySelector("#necessidadeHospedagem");
        if ((dados.necessidadeHospedagem ?? "") === "") {
            toast.error('É necessário preencher a Necessidade de Hospedagem');
            setTimeout(() => {
                necessidadeHospedagem.focus();
            }, 500);
            return false;
        }

        if ((dados.necessidadeHospedagem ?? "") === "sim") {
            // numeroDiariasHospedagem
            var numeroDiariasHospedagem = document.querySelector("#numeroDiariasHospedagem");
            if (numeroDiariasHospedagem.value === '') {
                toast.error('É necessário preencher o Número de Diárias de Hospedagem');
                setTimeout(() => {
                    numeroDiariasHospedagem.focus();
                }, 500);
                return false;
            }
        }

        // numeroAlmocoPessoa
        var numeroAlmocoPessoa = document.querySelector("#numeroAlmocoPessoa");
        if (numeroAlmocoPessoa.value === '') {
            toast.error('É necessário preencher o Número de Almoços por Pessoa');
            setTimeout(() => {
                numeroAlmocoPessoa.focus();
            }, 500);
            return false;
        }

        // numeroJantarPessoa
        var numeroJantarPessoa = document.querySelector("#numeroJantarPessoa");
        if (numeroJantarPessoa.value === '') {
            toast.error('É necessário preencher o Número de Jantares por Pessoa');
            setTimeout(() => {
                numeroJantarPessoa.focus();
            }, 500);
            return false;
        }

        // numeroCoffeeBreakPessoa
        var numeroCoffeeBreakPessoa = document.querySelector("#numeroCoffeeBreakPessoa");
        if (numeroCoffeeBreakPessoa.value === '') {
            toast.error('É necessário preencher o Número de Coffee Breaks por Pessoa');
            setTimeout(() => {
                numeroCoffeeBreakPessoa.focus();
            }, 500);
            return false;
        }

        // endereco
        var endereco = document.querySelector("#endereco");
        if (endereco.value === '') {
            toast.error('É necessário preencher o Endereço');
            setTimeout(() => {
                endereco.focus();
            }, 500);
            return false;
        }

        // local
        var local = document.querySelector("#local");
        if (local.value === '') {
            toast.error('É necessário preencher o Local');
            setTimeout(() => {
                local.focus();
            }, 500);
            return false;
        }

        // municipio
        var municipio = document.querySelector("#municipio");
        if ((dados.evento?.municipio?.id ?? "") === "") {
            toast.error('É necessário preencher o Município');
            setTimeout(() => {
                municipio.focus();
            }, 500);
            return false;
        }

        //enderecoCep
        var enderecoCep = document.querySelector("#enderecoCep");
        if (enderecoCep.value === '') {
            toast.error('É necessário preencher o CEP');
            setTimeout(() => {
                enderecoCep.focus();
            }, 500);
            return false;
        }

        //arquivo1
        var arquivo1 = document.querySelector("#arquivo1");
        if ((dados.nomeArquivo1 ?? "") === "") {
            toast.error('É necessário Anexar o Arquivo Agenda Transparency');
            setTimeout(() => {
                arquivo1.focus();
            }, 500);
            return false;
        }

        return true;
    };

    const salvar = () => {
        var dadosSalvar = { ...dados };

        if (dadosSalvar.evento.data) {
            dadosSalvar.evento.data = dataMysql(dadosSalvar.evento.data);
        }
        if (dadosSalvar.evento.dataFim) {
            dadosSalvar.evento.dataFim = dataMysql(dadosSalvar.evento.dataFim);
        }

        if (dadosSalvar.especialidade?.constructor === Array) {
            dadosSalvar.especialidade = dadosSalvar.especialidade.map(item => item.value).join(',');
        }

        if (dadosSalvar.tipoParticipanteAlvo?.constructor === Array) {
            dadosSalvar.tipoParticipanteAlvo = dadosSalvar.tipoParticipanteAlvo.map(item => item.value).join(',');
        }

        if (dadosSalvar.outrosprodutos?.constructor === Array) {
            dadosSalvar.outrosprodutos = dadosSalvar.outrosprodutos.map(item => item.value).join(',');
        }

        if (dadosSalvar.evento?.responsavel?.constructor === Array) {
            dadosSalvar.evento.responsavel = dadosSalvar.evento.responsavel.map(item => item.value).join(',');
        }

        if (dadosSalvar.evento?.responsavelcliente?.constructor === Array) {
            dadosSalvar.evento.responsavelcliente = dadosSalvar.evento.responsavelcliente.map(item => item.value).join(',');
        }

        if (id) {//update
            api({
                method: 'put',
                action: '/eventoBriefing/' + id,
                token: token,
                data: dadosSalvar
            })
                .then(function (response) {
                    //console.log(response.data);
                    if (['briefing', 'cliente'].includes(perfil)) {
                        toast.success("Briefing atualizado com sucesso!");
                        setEnviado(true);
                    } else {
                        history.push("/briefing");
                    }
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert
            api({
                method: 'post',
                action: '/eventoBriefing',
                token: token,
                data: dadosSalvar
            })
                .then(function (response) {
                    //console.log(response.data);
                    if (['briefing', 'cliente'].includes(perfil)) {
                        toast.success("Briefing registrado com sucesso!");
                        setEnviado(true);
                    } else {
                        history.push("/briefing");
                    }
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['briefing', 'cliente'].includes(perfil) ? false : true}>
            <div style={{
                backgroundImage: "url(/imagens/evento.png)",
                backgroundPositionY: "50%",
                minHeight: "400px",
                width: "100%",
                top: "-50px",
                position: "fixed",
            }}>
                <span className="mask bg-primary opacity-6"></span>
            </div>
            <div className="main-content container-fluid">
                <ul className="nav" style={{ position: "relative" }} >
                    {['briefing', 'cliente'].includes(perfil) ?
                        <NavItem to="/briefingCliente" icon={<FaBackward size={20} />} color="primary"> Voltar</NavItem> : null}
                </ul>
                <section id="multiple-column-form">
                    <div className="row match-height maisMargem">
                        <div className="col-lg-2">
                        </div>
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <h3>Evento Sanofi Organizado pela Força de Vendas ou Aérea Médica</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form">
                                            <h4>Orçamento</h4>
                                            <div className="row">

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Ordem Interna</label>
                                                        <input disabled={visualizar} type="text" className="form-control" id="ordemInterna" placeholder="Ordem Interna"
                                                            value={pontoVirgula(dados.ordemInterna)}
                                                            onChange={(e) => {
                                                                dados.ordemInterna = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Total Previsto</label>
                                                        <input disabled={visualizar} type="text" className="form-control right" id="totalPrevisto" placeholder="R$ 0,00"
                                                            value={pontoVirgula(dados.totalPrevisto)}
                                                            onChange={(e) => {
                                                                dados.totalPrevisto = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Informações Gerais</h4>
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <div className="form-group">
                                                        <label>Nome do Evento</label>
                                                        <input disabled={visualizar} type="text" className="form-control" id="nome" placeholder="Nome"
                                                            value={dados.evento?.nome}
                                                            onChange={(e) => {
                                                                dados.evento.nome = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo do Registro</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="tipo"
                                                            value={{ value: dados.evento?.tipo, label: dados.evento?.nomeTipo }}
                                                            onChange={(e) => {
                                                                dados.evento.tipo = e.value;
                                                                dados.evento.nomeTipo = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'speakmeet', label: 'Speaker Meeting (One-Time)' },
                                                                { value: 'scieneducpromee', label: 'Scientific Educational Program/Meeting' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Nacional ou Internacional</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="nacionalidade"
                                                            value={{ value: dados.evento?.nacionalidade, label: dados.evento?.nomeNacionalidade }}
                                                            onChange={(e) => {
                                                                dados.evento.nacionalidade = e.value;
                                                                dados.evento.nomeNacionalidade = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'nacional', label: 'Nacional' },
                                                                { value: 'internacional', label: 'Internacional' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>ID One CRM</label>
                                                        <input disabled={visualizar} type="text" className="form-control" id="identificacao" placeholder="ID One CRM"
                                                            value={dados.evento?.identificacao}
                                                            onChange={(e) => {
                                                                dados.evento.identificacao = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Agenda</h4>
                                            <div className="row">
                                                <div className="col-sm-1">
                                                </div>
                                                <div className="col-sm-3">
                                                    <Data disabled={visualizar}
                                                        labelExterno={true}
                                                        id={"data"}
                                                        label="Data Inicial"
                                                        value={dados.evento?.data}
                                                        onChange={(date) => {
                                                            dados.evento.data = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Horário Inicial</label>
                                                        <input disabled={visualizar} ask mask="99:99" className="form-control" id="hora" placeholder="00:00"
                                                            value={dados.evento?.hora}
                                                            onChange={(e) => {
                                                                dados.evento.hora = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-1">
                                                </div>
                                                <div className="col-sm-3">
                                                    <Data disabled={visualizar}
                                                        labelExterno={true}
                                                        id={"dataFim"}
                                                        label="Data Final"
                                                        value={dados.evento?.dataFim}
                                                        onChange={(date) => {
                                                            dados.evento.dataFim = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Horário Final</label>
                                                        <input disabled={visualizar} ask mask="99:99" className="form-control" id="horaFim" placeholder="00:00"
                                                            value={dados.evento?.horaFim}
                                                            onChange={(e) => {
                                                                dados.evento.horaFim = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Informações Abertura</h4>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Formato</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="tipoRealizacao"
                                                            value={{ value: dados.evento?.tipoRealizacao, label: dados.evento?.nomeTipoRealizacao }}
                                                            onChange={(e) => {
                                                                dados.evento.tipoRealizacao = e.value;
                                                                dados.evento.nomeTipoRealizacao = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'digital', label: 'Digital' },
                                                                { value: 'presencial', label: 'Presencial' },
                                                                { value: 'hibrido', label: 'Híbrido' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Unidade de Negócios</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="unidadenegocio"
                                                            value={{ value: dados.unidadenegocio, label: dados.nomeUnidadenegocio }}
                                                            onChange={e => setDados({ ...dados, ...{ "unidadenegocio": e.value, "nomeUnidadenegocio": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produtoUnidadeNegocio',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Produto Principal</label>
                                                        <div key={(dados.unidadenegocio ?? "nada")}>
                                                            <ReactSelect readOnly={visualizar}
                                                                id="produtoprincipal"
                                                                value={{ value: dados.produtoprincipal, label: dados.nomeProdutoprincipal }}
                                                                onChange={(e) => {
                                                                    dados.produtoprincipal = e.value;
                                                                    dados.nomeProdutoprincipal = e.label;
                                                                    dados.buProdutoPrincipal = e.bu?.id ?? null;
                                                                    setDados({ ...dados });
                                                                }}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/produto',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            modulo: 'orcamento',
                                                                            unidadenegocio: dados.unidadenegocio ?? null,
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map(registro => ({
                                                                                value: registro.id,
                                                                                label: registro.nome,
                                                                                bu: registro.bu ?? null
                                                                            }));
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Outros Produtos</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            isMulti={true}
                                                            value={dados.outrosprodutos}
                                                            onChange={(e) => {
                                                                if (e.length > 2) {
                                                                    toast.error('Máximo de 2 outros produtos');
                                                                } else {
                                                                    dados.outrosprodutos = dados.outrosprodutos || [];
                                                                    dados.outrosprodutos = e;
                                                                    setDados({ ...dados });
                                                                }
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produto',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        modulo: 'orcamento'
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Grupo de Pacientes Envolvidos?</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="grupoPacienteEnvolvido"
                                                            value={{ value: dados.grupoPacienteEnvolvido, label: dados.grupoPacienteEnvolvido === 'sim' ? 'Sim' : 'Não' }}
                                                            onChange={e => setDados({ ...dados, ...{ "grupoPacienteEnvolvido": e.value } })}
                                                            options={[
                                                                { value: 'sim', label: 'Sim' },
                                                                { value: 'nao', label: 'Não' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="requisitanteEvento">Nome do Requisitante do Evento</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="requisitanteEvento"
                                                            placeholder="Nome do Requisitante do Evento"
                                                            value={dados.requisitanteEvento}
                                                            onChange={e => setDados({ ...dados, requisitanteEvento: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="emailRequisitanteEvento">Email do Requisitante do Evento</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="emailRequisitanteEvento"
                                                            placeholder="Email do Requisitante do Evento"
                                                            value={dados.emailRequisitanteEvento}
                                                            onChange={e => setDados({ ...dados, emailRequisitanteEvento: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Suporte (Membros da Equipe)</h4>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Responsável na Agência</label>
                                                        <div key={(dados.evento?.nacionalidade ?? "nada") + (dados.unidadenegocio ?? "nada") + (dados.buProdutoPrincipal ?? "nada")}>
                                                            <ReactSelect readOnly={visualizar}
                                                                id="responsavel"
                                                                isMulti={true}
                                                                value={dados.evento?.responsavel ?? []}
                                                                onChange={(e) => {
                                                                    dados.evento.responsavel = dados.evento.responsavel || [];
                                                                    dados.evento.responsavel = e;
                                                                    setDados({ ...dados });
                                                                }}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/participante',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            tipoCadastro: "pessoa",
                                                                            situacaoRegistro: "ativo",
                                                                            perfil: [4], // 4 - Eventos
                                                                            unidadenegocio: dados.unidadenegocio ?? null,
                                                                            bu: dados.buProdutoPrincipal ?? null,
                                                                            nacionalidadeEvento: dados.evento?.nacionalidade ?? null,
                                                                            cliente: ["138390901623206513", "386322951623206507", "421477111623206520", "642423521623206521", "847226271623206506"] //apenas sanofi
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map((registro) => {
                                                                                var label = registro.nome;
                                                                                return {
                                                                                    value: registro.id,
                                                                                    label: label
                                                                                };
                                                                            });
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Responsável no Cliente (Focal Point Eventos)</label>
                                                        <div key={(dados.evento?.nacionalidade ?? "nada") + (dados.unidadenegocio ?? "nada") + (dados.buProdutoPrincipal ?? "nada")}>
                                                            <ReactSelect readOnly={visualizar}
                                                                id="responsavelcliente"
                                                                isMulti={true}
                                                                value={dados.evento?.responsavelcliente ?? []}
                                                                onChange={(e) => {
                                                                    dados.evento.responsavelcliente = dados.evento.responsavelcliente || [];
                                                                    dados.evento.responsavelcliente = e;
                                                                    setDados({ ...dados });
                                                                }}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/eventoResponsavelCliente',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            unidadenegocio: dados.unidadenegocio ?? null,
                                                                            bu: dados.buProdutoPrincipal ?? null,
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map((registro) => {
                                                                                var label = registro.nome;
                                                                                return {
                                                                                    value: registro.id,
                                                                                    label: label
                                                                                };
                                                                            });
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="membrosEquipe">Membros da Equipe / Estagiários</label>
                                                        <textarea disabled={visualizar}
                                                            className="form-control"
                                                            id="membrosEquipe"
                                                            placeholder="Membros da Equipe"
                                                            style={{ minHeight: "100px" }}
                                                            value={dados.membrosEquipe}
                                                            onChange={e => setDados({ ...dados, ...{ "membrosEquipe": e.target.value } })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Gerentes ou Aprovadores</h4>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="aprovadorUnidadeNegocio">Aprovador da Unidade de Negócios</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="aprovadorUnidadeNegocio"
                                                            placeholder="Aprovador da Unidade de Negócio"
                                                            value={dados.aprovadorUnidadeNegocio}
                                                            onChange={e => setDados({ ...dados, aprovadorUnidadeNegocio: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="aprovadorAreaMedica">Aprovador da Área Médica</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="aprovadorAreaMedica"
                                                            placeholder="Aprovador da Área Médica"
                                                            value={dados.aprovadorAreaMedica}
                                                            onChange={e => setDados({ ...dados, aprovadorAreaMedica: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Justificativa</h4>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="objetivoEvento">Objetivo do Evento</label>
                                                        <textarea disabled={visualizar}
                                                            className="form-control"
                                                            id="objetivoEvento"
                                                            placeholder="Objetivo do Evento"
                                                            style={{ minHeight: "100px" }}
                                                            value={dados.objetivoEvento}
                                                            onChange={e => setDados({ ...dados, objetivoEvento: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="necessidadesEvento">Necessidades do Evento</label>
                                                        <textarea disabled={visualizar}
                                                            className="form-control"
                                                            id="necessidadesEvento"
                                                            placeholder="Necessidades do Evento"
                                                            style={{ minHeight: "100px" }}
                                                            value={dados.necessidadesEvento}
                                                            onChange={e => setDados({ ...dados, necessidadesEvento: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <h4>Necessidade de Aconselhamento Externo</h4>
                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input disabled={visualizar} type="radio"
                                                                id="aconselhamentoExterno"
                                                                className="form-check-input"
                                                                value="SemRec"
                                                                checked={dados.aconselhamentoExterno === "SemRec"}
                                                                onChange={() => {
                                                                    dados.aconselhamentoExterno = "SemRec";
                                                                    setDados({ ...dados });
                                                                }} />
                                                            Nenhum recurso interno aplicável</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="ExpUnic"
                                                                checked={dados.aconselhamentoExterno === "ExpUnic"}
                                                                onChange={() => {
                                                                    dados.aconselhamentoExterno = "ExpUnic";
                                                                    setDados({ ...dados });
                                                                }} />
                                                            É necessários experiência e conhecimento únicos que não estão disponíveis dentro da Sanofi</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="PratClin"
                                                                checked={dados.aconselhamentoExterno === "PratClin"}
                                                                onChange={() => {
                                                                    dados.aconselhamentoExterno = "PratClin";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Insights práticos da prática clínica sobre pacientes e tratamentos são obrigatórios, os quais não estão disponíveis dentro da Sanofi
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="SEELider"
                                                                checked={dados.aconselhamentoExterno === "SEELider"}
                                                                onChange={() => {
                                                                    dados.aconselhamentoExterno = "SEELider";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Os SEEs selecionados são especialistas líderes no assunto, possuem experiência clínica prática e possuem as habilidades terapêuticas e experiência regional necessárias para entregar este programa de forma efetiva
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="InsExter"
                                                                checked={dados.aconselhamentoExterno === "InsExter"}
                                                                onChange={() => {
                                                                    dados.aconselhamentoExterno = "InsExter";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Os insights dos principais especialistas externos nessa área são importantes para a Sanofi porque fornecem à empresa novas perspectivas, avaliações objetivas e conselhos críticos
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="EspClin"
                                                                checked={dados.aconselhamentoExterno === "EspClin"}
                                                                onChange={() => {
                                                                    dados.aconselhamentoExterno = "EspClin";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Especialistas externos que tratam e cuidam de pacientes na prática nos proporcionam conhecimento e compreensão das necessidades médicas atuais
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="OutrosOp"
                                                                checked={dados.aconselhamentoExterno === "OutrosOp"}
                                                                onChange={() => {
                                                                    dados.aconselhamentoExterno = "OutrosOp";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Outros
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {((dados.aconselhamentoExterno ?? "") === "OutrosOp") ?
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="aconselhamentoExternoOutros">Informe qual</label>
                                                            <input
                                                                disabled={visualizar} type="text"
                                                                className="form-control"
                                                                id="aconselhamentoExternoOutros"
                                                                placeholder=""
                                                                value={dados.aconselhamentoExternoOutros}
                                                                onChange={e => setDados({ ...dados, aconselhamentoExternoOutros: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            <h4>Service/Deliverable/Advice Usage</h4>
                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} id="tipoEngajamento"
                                                                type="radio"
                                                                className="form-check-input"
                                                                value="EduTreino"
                                                                checked={dados.tipoEngajamento === "EduTreino"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "EduTreino";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Educar / Treinar outros EEs
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="Consul"
                                                                checked={dados.tipoEngajamento === "Consul"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "Consul";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Consultoria
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="MatMed"
                                                                checked={dados.tipoEngajamento === "MatMed"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "MatMed";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Preparação de materiais médicos
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="MatCom"
                                                                checked={dados.tipoEngajamento === "MatCom"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "MatCom";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Preparação de materiais comerciais
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="ContClin"
                                                                checked={dados.tipoEngajamento === "ContClin"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "ContClin";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            O aconselhamento, experiência e opinião clínica serão utilizados para desenvolver conteúdo para uma certa iniciativa
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="InsCase"
                                                                checked={dados.tipoEngajamento === "InsCase"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "InsCase";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Os insights coletados durante a reunião serão utilizados para a criação de um caso de doença coerente que apoie o(s) resultado(s) do engajamento
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="ConsInfo"
                                                                checked={dados.tipoEngajamento === "ConsInfo"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "ConsInfo";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Através da consolidação de informações para ajustar e moldar a estratégia da Sanofi para a área terapêutica
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="AdvBoard"
                                                                checked={dados.tipoEngajamento === "AdvBoard"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "AdvBoard";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Participar no Advisory Board;
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="ProgPlano"
                                                                checked={dados.tipoEngajamento === "ProgPlano"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "ProgPlano";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Desenvolver um programa e plano sobre o tema
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="VidGlobal"
                                                                checked={dados.tipoEngajamento === "VidGlobal"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "VidGlobal";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Vídeos gravados que serão disponibilizados para vários países
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8">
                                                <div className="form-check">
                                                    <div className="checkbox" style={{ backgroundColor: '#f7faff', borderRadius: '5px', margin: "5px" }}>
                                                        <label className='text-justify' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <input
                                                                disabled={visualizar} type="radio"
                                                                className="form-check-input"
                                                                value="OutrosOp"
                                                                checked={dados.tipoEngajamento === "OutrosOp"}
                                                                onChange={() => {
                                                                    dados.tipoEngajamento = "OutrosOp";
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            Outros
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {((dados.tipoEngajamento ?? "") === "OutrosOp") ?
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="tipoEngajamentoOutros">Informe qual</label>
                                                            <input
                                                                disabled={visualizar} type="text"
                                                                className="form-control"
                                                                id="tipoEngajamentoOutros"
                                                                placeholder=""
                                                                value={dados.tipoEngajamentoOutros}
                                                                onChange={e => setDados({ ...dados, tipoEngajamentoOutros: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            <hr />
                                            <h4>Detalhes dos Speakers</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="quantidadePalestrantes">Nº Estimado de Experts Externos (Speakers)</label>
                                                        <input
                                                            disabled={visualizar} type="number"
                                                            className="form-control right"
                                                            id="quantidadePalestrantes"
                                                            placeholder="0"
                                                            value={dados.quantidadePalestrantes}
                                                            onChange={e => setDados({ ...dados, quantidadePalestrantes: parseInt(e.target.value, 10) || null })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="areaTerapeuticaExpert">Área Terapêutica do Expert Externo</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="areaTerapeuticaExpert"
                                                            placeholder="Área Terapêutica do Expert"
                                                            value={dados.areaTerapeuticaExpert}
                                                            onChange={e => setDados({ ...dados, areaTerapeuticaExpert: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Cross bord - Speaker internacional?</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="crossBoardPalestranteInternacional"
                                                            value={{ value: dados.crossBoardPalestranteInternacional, label: dados.crossBoardPalestranteInternacional === 'sim' ? 'Sim' : 'Não' }}
                                                            onChange={e => setDados({ ...dados, ...{ "crossBoardPalestranteInternacional": e.value } })}
                                                            options={[
                                                                { value: 'sim', label: 'Sim' },
                                                                { value: 'nao', label: 'Não' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Detalhes dos Participantes</h4>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label htmlFor="quantidadeHcp">HCP Previsto</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control right"
                                                            id="quantidadeHcp"
                                                            placeholder="0"
                                                            value={dados.quantidadeHcp}
                                                            onChange={e => setDados({ ...dados, quantidadeHcp: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label htmlFor="quantidadeNaoHcp">Não HCP Previsto</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control right"
                                                            id="quantidadeNaoHcp"
                                                            placeholder="0"
                                                            value={dados.quantidadeNaoHcp}
                                                            onChange={e => setDados({ ...dados, quantidadeNaoHcp: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label htmlFor="quantidadeStaffs">Quantidade de STAFFS Previsto</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control right"
                                                            id="quantidadeStaffs"
                                                            placeholder="0"
                                                            value={dados.evento?.quantidadeStaffs}
                                                            onChange={(e) => {
                                                                dados.evento.quantidadeStaffs = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="racionalEscolhaParticipante">Racional para Escolha dos Participantes</label>
                                                        <textarea disabled={visualizar}
                                                            className="form-control"
                                                            id="racionalEscolhaParticipante"
                                                            placeholder="Racional para Escolha dos Participantes"
                                                            style={{ minHeight: "100px" }}
                                                            value={dados.racionalEscolhaParticipante}
                                                            onChange={e => setDados({ ...dados, racionalEscolhaParticipante: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo de Participante Alvo</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="tipoParticipanteAlvo"
                                                            isMulti={true}
                                                            value={dados.tipoParticipanteAlvo}
                                                            onChange={(e) => {
                                                                dados.tipoParticipanteAlvo = dados.tipoParticipanteAlvo || [];
                                                                dados.tipoParticipanteAlvo = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={
                                                                [
                                                                    { value: 'medico', label: 'Médico' },
                                                                    { value: 'enfermeiro', label: 'Enfermeiro' },
                                                                    { value: 'nonhcp', label: 'Non-HCP' },
                                                                    { value: 'paciente', label: 'Paciente' },
                                                                    { value: 'farmaceutico', label: 'Farmacêutico' },
                                                                    { value: 'staff', label: 'Staff' },
                                                                    { value: 'outros', label: 'Outros' },
                                                                ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="floatingInput">Outro tipo</label>
                                                        <input disabled={visualizar} type="text" className="form-control" id="tipoParticipanteOutro" placeholder="Tipo de Participante"
                                                            value={dados.tipoParticipanteOutro}
                                                            onChange={(e) => {
                                                                dados.tipoParticipanteOutro = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Especialidade</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="especialidade"
                                                            isMulti={true}
                                                            value={dados.especialidade}
                                                            onChange={(e) => {
                                                                dados.especialidade = dados.especialidade || [];
                                                                dados.especialidade = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participanteEspecialidade',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="outraespecialidade">Outra Especialidade</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="outraespecialidade"
                                                            placeholder="Outra Especialidade"
                                                            value={dados.outraespecialidade}
                                                            onChange={(e) => {
                                                                setDados({ ...dados, outraespecialidade: e.target.value });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Previsão de Hospitalidade por Participante Externo</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Necessita de Aéreo/Transfer?</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="necessidadeAereoTransfer"
                                                            value={{ value: dados.necessidadeAereoTransfer, label: dados.necessidadeAereoTransfer === 'sim' ? 'Sim' : 'Não' }}
                                                            onChange={e => setDados({ ...dados, ...{ "necessidadeAereoTransfer": e.value } })}
                                                            options={[
                                                                { value: 'sim', label: 'Sim' },
                                                                { value: 'nao', label: 'Não' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Necessita de Hospedagem?</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="necessidadeHospedagem"
                                                            value={{ value: dados.necessidadeHospedagem, label: dados.necessidadeHospedagem === 'sim' ? 'Sim' : 'Não' }}
                                                            onChange={e => setDados({ ...dados, ...{ "necessidadeHospedagem": e.value } })}
                                                            options={[
                                                                { value: 'sim', label: 'Sim' },
                                                                { value: 'nao', label: 'Não' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="numeroDiariasHospedagem">Número de diárias de hospedagem por pessoa</label>
                                                        <input
                                                            disabled={visualizar} type="number"
                                                            className="form-control right"
                                                            id="numeroDiariasHospedagem"
                                                            placeholder="0"
                                                            value={dados.numeroDiariasHospedagem}
                                                            onChange={e => setDados({ ...dados, numeroDiariasHospedagem: parseInt(e.target.value, 10) || null })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="numeroAlmocoPessoa">Número de almoços por pessoa</label>
                                                        <input
                                                            disabled={visualizar} type="number"
                                                            className="form-control right"
                                                            id="numeroAlmocoPessoa"
                                                            placeholder="0"
                                                            value={dados.numeroAlmocoPessoa}
                                                            onChange={e => setDados({ ...dados, numeroAlmocoPessoa: parseInt(e.target.value, 10) || null })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="numeroJantarPessoa">Número de jantares por pessoa</label>
                                                        <input
                                                            disabled={visualizar} type="number"
                                                            className="form-control right"
                                                            id="numeroJantarPessoa"
                                                            placeholder="0"
                                                            value={dados.numeroJantarPessoa}
                                                            onChange={e => setDados({ ...dados, numeroJantarPessoa: parseInt(e.target.value, 10) || null })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="numeroCoffeeBreakPessoa">Número de coffee breaks por pessoa</label>
                                                        <input
                                                            disabled={visualizar} type="number"
                                                            className="form-control right"
                                                            id="numeroCoffeeBreakPessoa"
                                                            placeholder="0"
                                                            value={dados.numeroCoffeeBreakPessoa}
                                                            onChange={e => setDados({ ...dados, numeroCoffeeBreakPessoa: parseInt(e.target.value, 10) || null })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Local do Evento</h4>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="endereco">Endereço</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="endereco"
                                                            placeholder="Endereço"
                                                            value={dados.evento?.endereco}
                                                            onChange={(e) => {
                                                                setDados({ ...dados, evento: { ...dados.evento, endereco: e.target.value } });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="local">Local</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="local"
                                                            placeholder="Local"
                                                            value={dados.evento?.local}
                                                            onChange={(e) => {
                                                                setDados({ ...dados, evento: { ...dados.evento, local: e.target.value } });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Cidade</label>
                                                        <ReactSelect readOnly={visualizar}
                                                            id="municipio"
                                                            value={{ value: dados.evento?.municipio?.id, label: dados.evento?.municipio?.nome }}
                                                            onChange={(e) => {
                                                                dados.evento.municipio = {};
                                                                dados.evento.municipio.id = e.value;
                                                                dados.evento.municipio.nome = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/municipio',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label htmlFor="enderecoCep">CEP</label>
                                                        <input
                                                            disabled={visualizar} type="text"
                                                            className="form-control"
                                                            id="enderecoCep"
                                                            placeholder="CEP"
                                                            value={dados.enderecoCep}
                                                            onChange={e => setDados({ ...dados, ...{ "enderecoCep": e.target.value } })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Arquivos</h4>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-floating form-group">
                                                        <input disabled={visualizar} type="file" multiple={false} className="form-control form-control-lg" id="arquivo1" placeholder=""
                                                            value={dados.nomeArquivo1}
                                                            onChange={(e) => {
                                                                var nomeArquivo1 = e.target.value;
                                                                //transforma binario em json hexadecimal
                                                                const fileReader = new FileReader();
                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                fileReader.onload = e => {
                                                                    setDados({
                                                                        ...dados,
                                                                        ...{
                                                                            "nomeArquivo1": nomeArquivo1,
                                                                            "binarioArquivo1": bin2hex(e.target.result),
                                                                        }
                                                                    });
                                                                };
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Agenda Transparency (Para eventos Sanofi e Simposios Sanofi em eventos de terceiros)</label>
                                                    </div>
                                                </div>
                                                {((dados.arquivo1?.link ?? '') !== '') ?
                                                    <div className="col-sm-8">
                                                        <div className="form-floating form-group">
                                                            <FormButton
                                                                method="get"
                                                                title="Agenda Transparency (Para eventos Sanofi e Simposios Sanofi em eventos de terceiros)"
                                                                action={process.env.REACT_APP_API_URL + "/briefingArquivo/" + (dados.arquivo1?.id ?? "")}
                                                                icon={<FaDownload size={20} />}
                                                                color="primary"
                                                                data={{
                                                                    token: localStorage.getItem('token'),
                                                                    tipoArquivo: "briefingArquivo"
                                                                }}
                                                            />
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-floating form-group">
                                                        <input disabled={visualizar} type="file" multiple={false} className="form-control form-control-lg" id="arquivo2" placeholder=""
                                                            value={dados.nomeArquivo2}
                                                            onChange={(e) => {
                                                                var nomeArquivo2 = e.target.value;
                                                                //transforma binario em json hexadecimal
                                                                const fileReader = new FileReader();
                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                fileReader.onload = e => {
                                                                    setDados({
                                                                        ...dados,
                                                                        ...{
                                                                            "nomeArquivo2": nomeArquivo2,
                                                                            "binarioArquivo2": bin2hex(e.target.result),
                                                                        }
                                                                    });
                                                                };
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Outros</label>
                                                    </div>
                                                </div>
                                                {((dados.arquivo2?.link ?? '') !== '') ?
                                                    <div className="col-sm-8">
                                                        <div className="form-floating form-group">
                                                            <FormButton
                                                                method="get"
                                                                title="Outros"
                                                                action={process.env.REACT_APP_API_URL + "/briefingArquivo/" + (dados.arquivo2?.id ?? "")}
                                                                icon={<FaDownload size={20} />}
                                                                color="primary"
                                                                data={{
                                                                    token: localStorage.getItem('token'),
                                                                    tipoArquivo: "briefingArquivo"
                                                                }}
                                                            />
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            <hr />
                                            <h4>Observações</h4>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <textarea disabled={visualizar}
                                                            className="form-control form-control-lg"
                                                            id="observacao"
                                                            placeholder="Observações"
                                                            style={{ minHeight: "100px" }}
                                                            value={dados.observacao}
                                                            onChange={e => setDados({ ...dados, ...{ "observacao": e.target.value } })}
                                                        />
                                                        <label htmlFor="observacao">Observações</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {!visualizar ?
                                                <div className="row fixoRodape">
                                                    <div className="col-12 d-flex justify-content-end">
                                                        {/*
                                                        <button
                                                            type='button'
                                                            className="btn btn-primary me-1 mb-1"
                                                            onClick={() => {
                                                                dados.evento.situacao = 'rascunhoBriefing';
                                                                setDados({ ...dados });
                                                                if (validar()) {
                                                                    salvar();
                                                                }
                                                            }}>
                                                            <FaSave size={20} /> Salvar Rascunho</button>
                                                        */}
                                                        {!enviado ?
                                                            <button
                                                                type='button'
                                                                className="btn btn-primary me-1 mb-1"
                                                                onClick={() => {
                                                                    dados.evento.situacao = 'briefing';
                                                                    dados.enviar = true;
                                                                    setDados({ ...dados });
                                                                    if (validarTudo()) { //só salva se estiver validado
                                                                        salvar();
                                                                    }
                                                                }}>
                                                                <FaPaperPlane size={20} /> Enviar</button>
                                                            : null}
                                                    </div>
                                                </div> : null}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}